import { useState } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Outlet } from "react-router-dom";
import { Link } from 'react-router-dom';

function Layout() {
    const [expanded, setExpanded] = useState(false);

    return (
        <>
        <Navbar bg="dark" variant="dark" expand="lg" className="mb-2" expanded={expanded}>
            <Container>
                <Link to="/" className="navbar-brand">MotoReports.com</Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav"  onClick={() => setExpanded(!expanded)}/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Link to="/stolen" data-rr-ui-event-key="/stolen" className="nav-link" onClick={() => setExpanded(false)}>Zagtie moči</Link>
                        <Link to="/news" data-rr-ui-event-key="/news" className="nav-link" onClick={() => setExpanded(false)}>Jaunumi</Link>
                        <Link to="/blog" data-rr-ui-event-key="/blog" className="nav-link" onClick={() => setExpanded(false)}>Blogs</Link>
                        <Link to="/contacts" data-rr-ui-event-key="/contacts" className="nav-link" onClick={() => setExpanded(false)}>Par mums</Link>
                        <Link to="/login" data-rr-ui-event-key="/login" className="nav-link" onClick={() => setExpanded(false)}>Reģistrēties / Ieiet</Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

        <Outlet />
        </>
    );
}

export default Layout;