import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

function Stolen() {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6 col-lg-4 mb-2">
                    <Card>
                        <Card.Img variant="top" src="/logo192.png" />
                        <Card.Body>
                            <Card.Title>XXXXXX000000000000</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">HONDA CRF450 2010</Card.Subtitle>
                            <Card.Text className="mb-1">Valsts: Latvija</Card.Text>
                            <Card.Text className="mb-2">Nozagts: 24.04.2018</Card.Text>
                            <div className="text-center">
                                <Button variant="dark">Apskatīt</Button>
                            </div>
                        </Card.Body>
                    </Card>
                </div>

                <div className="col-md-6 col-lg-4 mb-2">
                    <Card>
                        <Card.Img variant="top" src="/logo192.png" />
                        <Card.Body>
                            <Card.Title>XXXXXX000000000000</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">HONDA CRF450 2010</Card.Subtitle>
                            <Card.Text className="mb-1">Valsts: Latvija</Card.Text>
                            <Card.Text className="mb-2">Nozagts: 24.04.2018</Card.Text>
                            <div className="text-center">
                                <Button variant="dark">Apskatīt</Button>
                            </div>
                        </Card.Body>
                    </Card>
                </div>

                <div className="col-md-6 col-lg-4 mb-2">
                    <Card>
                        <Card.Img variant="top" src="/logo192.png" />
                        <Card.Body>
                            <Card.Title>XXXXXX000000000000</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">HONDA CRF450 2010</Card.Subtitle>
                            <Card.Text className="mb-1">Valsts: Latvija</Card.Text>
                            <Card.Text className="mb-2">Nozagts: 24.04.2018</Card.Text>
                            <div className="text-center">
                                <Button variant="dark">Apskatīt</Button>
                            </div>
                        </Card.Body>
                    </Card>
                </div>

                <div className="col-md-6 col-lg-4 mb-2">
                    <Card>
                        <Card.Img variant="top" src="/logo192.png" />
                        <Card.Body>
                            <Card.Title>XXXXXX000000000000</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">HONDA CRF450 2010</Card.Subtitle>
                            <Card.Text className="mb-1">Valsts: Latvija</Card.Text>
                            <Card.Text className="mb-2">Nozagts: 24.04.2018</Card.Text>
                            <div className="text-center">
                                <Button variant="dark">Apskatīt</Button>
                            </div>
                        </Card.Body>
                    </Card>
                </div>

                <div className="col-md-6 col-lg-4 mb-2">
                    <Card>
                        <Card.Img variant="top" src="/logo192.png" />
                        <Card.Body>
                            <Card.Title>XXXXXX000000000000</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">HONDA CRF450 2010</Card.Subtitle>
                            <Card.Text className="mb-1">Valsts: Latvija</Card.Text>
                            <Card.Text className="mb-2">Nozagts: 24.04.2018</Card.Text>
                            <div className="text-center">
                                <Button variant="dark">Apskatīt</Button>
                            </div>
                        </Card.Body>
                    </Card>
                </div>

                <div className="col-md-6 col-lg-4 mb-2">
                    <Card>
                        <Card.Img variant="top" src="/logo192.png" />
                        <Card.Body>
                            <Card.Title>XXXXXX000000000000</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">HONDA CRF450 2010</Card.Subtitle>
                            <Card.Text className="mb-1">Valsts: Latvija</Card.Text>
                            <Card.Text className="mb-2">Nozagts: 24.04.2018</Card.Text>
                            <div className="text-center">
                                <Button variant="dark">Apskatīt</Button>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default Stolen;