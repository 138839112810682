// import logo from './logo.svg';
// import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './pages/home.js';
import Layout from './pages/layout.js';
import Stolen from './pages/stolen.js';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NoPage from "./pages/no-page";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="stolen" element={<Stolen />} />
                <Route path="vin/*" element={<Stolen />} />
                <Route path="*" element={<NoPage />} />
            </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
