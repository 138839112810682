import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

function Home() {
    return (
        <>
            <div className="container">
                <div className="text-center">
                    <div className="row mb-2 mt-5">
                        <div className="col-12">
                            <h1>
                                Nezini motocikla vēsturi? Pārbaudi!
                            </h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-4 offset-md-3 offset-lg-4">
                            <InputGroup className="mb-2">
                                <Form.Control
                                    placeholder="VIN"
                                    aria-label="VIN"
                                    aria-describedby="basic-addon2"
                                />
                                <Button className="btn-light" variant="outline-secondary" id="button-addon2">
                                    Meklēt
                                </Button>
                            </InputGroup>
                        </div>
                    </div>

                    <div className="row mb-5 mt-5">
                        <div className="col-12">
                            vai
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-12">
                            <Button variant="light" size="lg">
                                Meklēt pēc numura
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;